<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :loading="loading"
    >

      <template #title>
        <div class="flex flex-col">
          <p>{{project.name}}</p>
          <p class="text-xs font-thin text-content">Wishlist</p>
        </div>
      </template>

      <template #content>
        <div class="`
          flex flex-col items-stretch self-center h-full pt-10
          w-5/6 phone:w-3/4 md:w-2/3 lg:w-3/5 max-w-md
          mb-10 pb-10
        `">
          <p
            v-if="!wishlistItemsExist"
            class="px-16 pt-20 text-content"
          >
            You have no wishlist items for this project
          </p>
          <div
            v-for="checklist in checklists" :key="`checklist-id-${checklist.id}`"
          >
            <div v-if="wishlistFilter(checklist.items).length > 0">
              <div
                @click="$router.push({name: 'Checklist', params: {checklist_id: checklist.id}})"
                class="`
                  w-full mb-3 text-2xl text-left
                  cursor-pointer text-content hover:underline
                  font-bold
                `"
              >
                {{checklist.name}}
              </div>
              <checklist-item-component
                v-for="(item) in wishlistFilter(checklist.items)" :key="item.name"
                @update:complete="toggleComplete({listId: checklist.id, id: item.id, post: $event})"
                @update:wishlist="toggleWishlist({listId: checklist.id, id: item.id, post: $event})"
                :name='item.name'
                :url='item.url'
                :description='item.description'
                :complete.sync="item.completed"
                :wishlist.sync="item.onWishlist"
                :disable="projectPermissions === 'basic'"
                class="mb-4"
              />
            </div>
          </div>
        </div>
      </template>

    </interior-page-content-container>
</template>

<script>

import { mapProjectFields } from '@/store/mappers';
import {
  PROJECT_GET,
  CHECKLISTS_GET,
  CHECKLIST_ITEM_COMPLETE,
  CHECKLIST_ITEM_INCOMPLETE,
  CHECKLIST_ITEM_ON_WISHLIST,
  CHECKLIST_ITEM_NOT_ON_WISHLIST,
} from '@/store/actions';
import {
  PROJECT_PERMISSIONS_CURRENT,
} from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ChecklistItemComponent from '@/components/projects/checklists/ChecklistItemComponent.vue';

export default {
  name: 'ProjectWishlist',
  components: {
    InteriorPageContentContainer,
    ChecklistItemComponent,
  },
  data() {
    return {
      loading: true,
      checklists: [],
      projectPermissions: '----',
      prevRoute: {},
    };
  },
  computed: {
    ...mapProjectFields(['project', 'projects']),
    wishlistItemsExist() {
      return this.checklists.reduce(
        (pastVal, curVal) => (this.wishlistFilter(curVal.items).length > 0 ? true : pastVal),
        false,
      );
    },
  },
  async created() {
    this.loading = !this.projects[this.$route.params.project_id];
    this.project = this.projects[this.$route.params.project_id] || {};

    this.$store.dispatch(PROJECT_GET, this.$route.params.project_id)
      .then(() => {
        this.projectPermissions = this.$store.getters[PROJECT_PERMISSIONS_CURRENT];
      })
      .catch(() => this.$router.push({ name: 'PageNotFound' }))
      .finally(() => { this.loading = false; });

    this.$store.dispatch(CHECKLISTS_GET, this.$route.params.project_id)
      .then((checklists) => { this.checklists = checklists; });
  },
  methods: {
    backNav() {
      this.$router.go(-1);
    },
    wishlistFilter(items) {
      const filteredItems = items.filter((item) => item.onWishlist);
      return filteredItems.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
    },
    toggleComplete(data) {
      this.$store.dispatch(data.post
        ? CHECKLIST_ITEM_COMPLETE
        : CHECKLIST_ITEM_INCOMPLETE, {
        projectId: this.$route.params.project_id,
        checklistId: data.listId,
        checklistItemId: data.id,
      });
    },
    toggleWishlist(data) {
      this.$store.dispatch(data.post
        ? CHECKLIST_ITEM_ON_WISHLIST
        : CHECKLIST_ITEM_NOT_ON_WISHLIST, {
        projectId: this.$route.params.project_id,
        checklistId: data.listId,
        checklistItemId: data.id,
      });
    },
  },
};
</script>
